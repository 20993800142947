<template>
  <template class="container" v-if="!loggedIn && !twofactor">
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-md-6 align-self-center">
        <div class="card">
          <auth-header :page-title="pageTitle" :companyColor="companyColor" v-if="companyColor">
          </auth-header>

          <!-- start vuerouter new change -->
          <router-view v-slot="{ Component }" :companyColor="companyColor" v-if="companyColor">
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
          <!-- end vuerouter new change -->

          <footer-auth :companyColor="companyColor"></footer-auth>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed, ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import CompanyColorService from "@/services/general/CompanyColorService";
import { useRoute } from "vue-router";

const module = "auth";
const companyColorModule = "companycolors";

export default defineComponent({
  name: "AuthLayout",

  setup(props) {
    const store = useStore();
    const route = useRoute();

    let loggedIn = ref(false);
    let twofactor = ref(false);
    let activeCompanyColor = ref({});
    const pageTitle = ref("")

    const companyColorService = new CompanyColorService();

    loggedIn = computed(() => {
      return store.getters[`${module}/loggedIn`];
    });

    twofactor = computed(() => {
      return store.getters[`${module}/twofactor`];
    });

    onMounted(async () => {
      // Fetch activeCompanyColor
      const companyColor = await companyColorService.getActiveCompanyColor(
        store,
        companyColorModule
      );
      activeCompanyColor.value = companyColor;
    });

    watch(
      () => route.meta.title,
      (newTitle) => {
        pageTitle.value = newTitle || "Enter details below to continue";
      },
      { immediate: true }
    );

    return {
      twofactor,
      loggedIn,
      companyColor: activeCompanyColor,
      pageTitle,
    };
  },
});
</script>
