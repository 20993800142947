import service from './services'
export default {
    async getStats({ commit }) {
        return service.getStats()
            .then(response => {
                commit('SET_STATS', response)
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    async getTransactions({ commit }, payload) {
        return service.getTransactions(payload)
            .then(response => {
                commit('SET_TRANSACTIONS', response)
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    async getDepositAccounts({ commit }, payload) {
        return service.getDepositAccounts(payload)
            .then(response => {
                commit('SET_DEPOSIT_ACCOUNTS', response)
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    async getDepositStatements({ commit }, payload) {
        return service.getDepositStatements(payload)
            .then(response => {
                commit('SET_DEPOSIT_STATEMENTS', response)
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    async getDepositTransactions({ commit }, payload) {
        return service.getDepositTransactions(payload)
            .then(response => {
                commit('SET_DEPOSIT_TRANSACTIONS', response)
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    async getDepositBalances({ commit }, payload) {
        return service.getDepositBalances(payload)
            .then(response => {
                commit('SET_DEPOSIT_BALANCES', response)
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    async postDeposit({ commit }, payload) {
        return service.postDeposit(payload)
            .then(response => {
                commit('ADD_DEPOSIT', response)
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    async postDepositTransfer({ commit }, payload) {
        return service.postDepositTransfer(payload)
            .then(response => {
                commit('TRANSFER_DEPOSIT', response)
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    async getShareAccounts({ commit }, payload) {
        return service.getShareAccounts(payload)
            .then(response => {
                commit('SET_SHARE_ACCOUNTS', response)
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    async getShareStatement({ commit }, payload) {
        return service.getShareStatement(payload)
            .then(response => {
                commit('SET_SHARE_STATEMENT', response)
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    async getShareBalance({ commit }, payload) {
        return service.getShareBalance(payload)
            .then(response => {
                commit('SET_SHARE_BALANCE', response)
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    async postSharePurchase({ commit }, payload) {
        return service.postSharePurchase(payload)
            .then(response => {
                commit('SET_SHARE_PURCHASE_RECEIPT', response)
                commit('UPDATE_SHARE_BALANCE', response.amount)
                commit('UPDATE_SHARE_ACCOUNT', response)
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    async postShareTransfer({ commit }, payload) {
        // TODO confirm response payload and update appropriately
        return service.postShareTransfer(payload)
            .then(response => {
                // commit('SET_SHARE_PURCHASE_RECEIPT', response)
                // commit('UPDATE_SHARE_BALANCE', response.amount)
                // commit('UPDATE_SHARE_ACCOUNT', response)
                this.getShareAccounts();
                this.getShareBalance(payload)
                this.getShareStatement(payload)
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    async getLoans({ commit }, payload) {
        return service.getLoans(payload)
            .then(response => {
                commit('SET_LOANS', response)
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    async getLoanStatement({ commit }, payload) {
        return service.getLoanStatement(payload)
            .then(response => {
                commit('SET_LOAN_STATEMENT', response)
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    async getLoanLimit({ commit }, payload) {
        return service.getLoanLimit(payload)
            .then(response => {
                commit('SET_LOAN_LIMIT', response)
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    async getLoanProducts({ commit }, payload) {
        return service.getLoanProducts(payload)
            .then(response => {
                commit('SET_LOAN_PRODUCTS', response)
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    async repayLoan({ commit }, payload) {
        return service.postLoanRepay(payload)
            .then(response => {
                commit('UPDATE_LOAN_BALANCE', response)
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    async getLoanApplications({ commit }, payload) {
        return service.getLoanApplications(payload)
            .then(response => {
                commit('SET_LOAN_APPLICATIONS', response)
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },
    async applyLoan({ commit }, payload) {
        return service.postLoanApplications(payload)
            .then(response => {
                commit('SET_LOAN_APPLICATION', response)
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    },

}