export default {
    SET_STATS(state, payload) {
        state.stats = payload
    },
    SET_TRANSACTIONS(state, payload) {
        state.transactions = payload
    },
    SET_DEPOSIT_ACCOUNTS(state, payload) {
        state.deposit_accounts = payload
    },
    SET_DEPOSIT_STATEMENTS(state, payload) {
        state.deposit_statements = payload
    },
    SET_DEPOSIT_TRANSACTIONS(state, payload) {
        state.deposit_transactions = payload
    },
    SET_DEPOSIT_BALANCES(state, payload) {
        state.deposit_balances = payload
    },
    UPDATE_DEPOSIT_ACCOUNT(state, payload) {
        // TODO update deposit specific deposit account of the new deposit made 
        // and update the balance as well
        state.deposit_accounts = state.deposit_accounts
    },
    TRANSFER_DEPOSIT(state, payload) {
        // TODO update the relevant accounts of the transfer made both debited account
        // and credited account
        state.deposit_accounts = payload
    },
    SET_SHARE_ACCOUNTS(state, payload) {
        state.share_accounts = payload
    },
    SET_SHARE_STATEMENT(state, payload) {
        state.deposit_statement = payload
    },
    SET_SHARE_BALANCE(state, payload) {
        state.share_balance = payload
    },
    SET_SHARE_PURCHASE_RECEIPT(state, payload) {
        state.share_receipt = payload
    },
    UPDATE_SHARE_BALANCE(state, payload) {
        state.share_balance = payload
        state.share_balances = state.share_balances.map(item => {
            if (item.id == payload.id) {
                item.amount + payload.amount
            }
            return item
        })
    },
    UPDATE_SHARE_ACCOUNT(state, payload) {
        state.share_account = payload
    },
    SET_LOANS(state, payload) {
        state.loan_loans = payload
    },
    SET_LOAN_STATEMENT(state, payload) {
        state.loan_statement = payload
    },
    SET_LOAN_LIMIT(state, payload) {
        state.loan_limit = payload
    },
    SET_LOAN_PRODUCTS(state, payload) {
        state.loan_products = payload
    },
    UPDATE_LOAN_BALANCE(state, payload) {
        state.loan_balance += payload.amount

    },
    SET_LOAN_APPLICATIONS(state, payload) {
        state.loan_applications = payload
    },
    SET_LOAN_APPLICATION(state, payload) {
        state.loan_application = payload
        this.SET_LOAN_APPLICATIONS = [payload, ...state.loan_applications]
    },
}