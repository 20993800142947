<template>
  <div class="topbar">
    <nav class="navbar-custom">
      <ul class="list-unstyled topbar-nav float-right mb-0">
        <li style="margin-right: 4rem" class="right">
          <div class="topbar-title justify-content-center align-right">
            <transition name="ptitle">
              <h6 class="d-none d-md-block">
                <span class="username-title" v-if="authUser?.employee">
                  {{ authUser?.employee?.full_name }}
                </span>
                <span class="username-title" v-else>
                  {{ authUser?.customer?.full_name }}
                </span>
                <br />
                <span class="company-title">
                  {{ authUser.active_company?.company_name }}
                </span>
                <!-- {{ authUser.employee.branch_id }} -->

                <div id="notification"></div>
              </h6>
            </transition>
          </div>
        </li>
        <li class="dropdown hide-phone">
          <!-- <a
                class="nav-link dropdown-toggle arrow-none waves-light waves-effect"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
                ><i data-feather="search" class="topbar-icon"></i
            ></a> -->
          <!-- <div class="dropdown-menu dropdown-menu-right dropdown-lg p-0">
                <div class="app-search-topbar">
                <form action="#" method="get">
                    <input
                    type="search"
                    name="search"
                    class="from-control top-search mb-0"
                    placeholder="Type text..."
                    />
                    <button type="submit"><i class="ti-search"></i></button>
                </form>
                </div>
            </div> -->
        </li>
        <li class="dropdown notification-list">
          <a class="nav-link dropdown-toggle arrow-none waves-light waves-effect" data-toggle="dropdown" href="#"
            role="button" aria-haspopup="false" aria-expanded="false">
            <i data-feather="bell" class="align-self-center topbar-icon"></i>
            <span class="badge badge-danger badge-pill noti-icon-badge">
              {{ activity?.length }}
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-right dropdown-lg pt-0">
            <h6 class="
                dropdown-item-text
                font-15
                m-0
                py-3
                border-bottom
                d-flex
                justify-content-between
                align-items-center
              ">
              Notifications
              <span class="badge badge-primary badge-pill">
                {{ activity?.length }}
              </span>
            </h6>
            <div class="notification-menu" data-simplebar>
              <router-link class="dropdown-item py-3" v-for="note in activity" :key="note.id"
                :to="note.data.link || `/admin/activity`">
                <small class="float-right text-muted pl-2 d-none">
                  2 min ago
                </small>
                <div class="media">
                  <div class="avatar-md bg-soft-primary">
                    <i data-feather="shopping-cart" class="align-self-center icon-xs"></i>
                  </div>
                  <div class="media-body align-self-center ml-2 text-truncate">
                    <h6 class="my-0 font-weight-normal text-dark">
                      {{ note.data.subject }}
                    </h6>
                    <small class="text-muted mb-0">
                      {{ note.data.message }}
                    </small>
                  </div>
                </div>
              </router-link>
            </div>

            <router-link to="/admin/activity" class="dropdown-item text-center text-primary">
              View all
              <i class="fi-arrow-right"></i>
            </router-link>
          </div>
        </li>

        <li class="dropdown">
          <!-- <a class="nav-link dropdown-toggle waves-effect waves-light nav-user"
                                data-toggle="dropdown" href="#" role="button" aria-haspopup="false"
                                aria-expanded="false">
                                <span class="ml-1 nav-user-name hidden-sm">Nick</span>
                                <img
                                    src="assets/images/users/user-5.jpg" alt="profile-user" class="rounded-circle">
                            </a> -->

          <a class="nav-link dropdown-toggle waves-effect waves-light nav-user" data-toggle="dropdown" href="#"
            role="button" aria-haspopup="false" aria-expanded="false">

            <!-- <img
              src="./../../assets/images/users/no-user.jpg"
              alt="profile-user"
              class="rounded-circle"/> -->

            <img v-if="authUser?.customer?.member_photo?.length > 0"
              :src="authUser?.customer?.member_photo[0].upload_path" :alt="authUser?.customer?.full_name"
              class="rounded-circle">

            <img v-else src="./../../assets/images/users/no-user.jpg" :alt="authUser?.customer?.full_name"
              class="rounded-circle" />

            <!-- <span class="ml-1 nav-user-name hidden-sm" v-if="userLoggedInData!==null"
                >{{userLoggedInData.first_name }} {{ userLoggedInData.last_name }}</span
                > -->
            <!-- <span class="ml-1 nav-user-name hidden-sm" v-if="$auth.check()"
                >{{ authUser.first_name }} {{ authUser.last_name }}</span
                > -->
            <!-- <span class="ml-1 nav-user-name hidden-sm">
                    Hussein
                </span>
                <br>
                <span class="ml-1 nav-user-name hidden-sm">
                    Equity Sacco
                </span> -->
          </a>
          <div class="dropdown-menu dropdown-menu-right">

            <router-link to="/profile" class="dropdown-item" v-if="authUser?.employee">
              <i data-feather="user" class="align-self-center icon-xs icon-dual mr-1"></i>My Profile
            </router-link>

            <router-link :to="{ name: 'clientCustomerProfile' }" class="dropdown-item" v-else>
              <i data-feather="user" class="align-self-center icon-xs icon-dual mr-1"></i>My Profile
            </router-link>

            <a class="dropdown-item" href="#" @click.prevent="toggleSelfPasswordPopup()">
              <i data-feather="lock" class="align-self-center icon-xs icon-dual mr-1"></i>Change PIN
            </a>

            <a class="dropdown-item" href="#"><i data-feather="settings"
                class="align-self-center icon-xs icon-dual mr-1"></i>
              Settings</a>
            <div class="dropdown-divider mb-0"></div>
            <a class="dropdown-item" href="#" @click="logout"><i data-feather="power"
                class="align-self-center icon-xs icon-dual mr-1"></i>
              Logout</a>
          </div>
        </li>
      </ul>

      <ul class="list-unstyled topbar-nav mb-0 d-flex">
        <li>
          <button class="nav-link button-menu-mobile">
            <i data-feather="menu" class="align-self-center topbar-icon"></i>
          </button>
        </li>
        <li style="padding-top: 0.8rem" class="center">
          <div class="topbar-title justify-content-center align-middle">
            <transition name="ptitle">
              <h4 class="page-title">{{ pageTitle }}</h4>
            </transition>
          </div>
        </li>
      </ul>
    </nav>
  </div>

  <change-self-password :toggle-popup="toggleSelfPasswordPopup" @togglePopup="toggleSelfPasswordPopup"
    v-if="show_change_self_password_modal">
  </change-self-password>

</template>

<style scoped>
.ptitle-enter-from {
  opacity: 0;
  transform: translateY(-60px);
}

.ptitle-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.ptitle-enter-active {
  transition: all 2s ease;
}

.ptitle-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.ptitle-leave-to {
  opacity: 0;
  transform: translateY(60px);
}

.ptitle-leave-active {
  transition: all 2s ease;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import { useStore } from "vuex";

export default {
  props: ["authUser"],

  data() {
    return {
      show_change_self_password_modal: false,
      dialogTableVisible: false,
      pageTitle: "",
      activity: [],
    };
  },

  mounted() {
    function a() {
      $(document).ready(function () {
        $("#AllCompo")
          .autocomplete({
            source: ["Forms", "Tables", "Charts", "Icones", "Maps"],
            minLength: 0,
            scroll: !0,
          })
          .focus(function () {
            $(this).autocomplete("search", "");
          });
      });
    }

    feather.replace();

    a();
    $(".navigation-menu a").each(function () {
      var t = window.location.href.split(/[?#]/)[0];
      this.href == t &&
        ($(this).parent().addClass("active"),
          $(this).parent().parent().parent().addClass("active"),
          $(this)
            .parent()
            .parent()
            .parent()
            .parent()
            .parent()
            .addClass("active"));
    });

    $(".navbar-toggle").on("click", function (t) {
      $(this).toggleClass("open"), $("#navigation").slideToggle(400);
    });

    $(".navigation-menu>li").slice(-2).addClass("last-elements");

    $('.navigation-menu li.has-submenu a[href="#"]').on("click", function (t) {
      $(window).width() < 992 &&
        (t.preventDefault(),
          $(this)
            .parent("li")
            .toggleClass("open")
            .find(".submenu:first")
            .toggleClass("open"));
    });

    // Waves.init();
  },

  watch: {
    // watch for changes in route and update page title as appropriate
    $route(route) {
      // if you go to the redirect page, do not update
      if (route.path.startsWith("/redirect/")) {
        return;
      }
      this.getPageTitle();
    },
  },

  created() {
    this.getPageTitle();

    // this.getActivity();
  },

  methods: {
    /* getActivity() {
        this.$store
            .dispatch(`notifications/fetchNotifications`)
            .then((res) => {
                // console.log("Topbar getActivity res === ", res);
                this.activity = res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }, */

    // toggleSelfPasswordPopup
    toggleSelfPasswordPopup() {
      this.show_change_self_password_modal = !this.show_change_self_password_modal;
    },

    /* logout() {
            this.$auth.logout();
            // console.log("this.$router == ", this.$router);
            this.$router.replace({ name: 'login'});
            location.reload();
        }, */

    getPageTitle() {
      // only show routes with meta.title
      let matched = this.$route.matched.filter(
        (item) => item.meta && item.meta.title
      );
      const first = matched[matched.length - 1];
      if (first) {
        // console.log("first == ", first, first.meta.title);
        this.pageTitle = first.meta.title;
      }
    },

    ...mapActions(["auth/logout"]),

    ...mapActions({
      logout: "auth/logout",
    }),
  },
};
</script>
