import Base from "../../Common/Base.vue";
import companyRegistrationRoutes from "./Company/companyRegistrationRoutes";
import userRegistrationRoutes from "./User/userRegistrationRoutes";
import paybillRegistrationRoutes from "./Paybill/paybillRegistrationRoutes";
import branchRegistrationRoutes from "./Branch/branchRegistrationRoutes";
import customerRegistrationRoutes from "./Customer/customerRegistrationRoutes";
import employeeRegistrationRoutes from "./Employee/employeeRegistrationRoutes";
import groupRegistrationRoutes from "./Group/groupRegistrationRoutes";
import groupCategoryRegistrationRoutes from "./GroupCategory/groupCategoryRegistrationRoutes";
import groupSubCategoryRegistrationRoutes from "./GroupSubCategory/groupSubCategoryRegistrationRoutes";
import groupRequestRegistrationRoutes from "./GroupRequest/groupRequestRegistrationRoutes";
import groupAdminRegistrationRoutes from "./GroupAdmin/groupAdminRegistrationRoutes";
import userStagingRegistrationRoutes from "./UserStaging/userStagingRegistrationRoutes";
import vendorRegistrationRoutes from "./Vendor/vendorRegistrationRoutes";
import customerCallRegistrationRoutes from "./CustomerCall/customerCallRegistrationRoutes";
import customerEmployeeAllocationRegistrationRoutes from "./CustomerEmployeeAllocation/customerEmployeeAllocationRegistrationRoutes";
import teamRoutes from "./Team/teamRoutes";
import teamMemberRoutes from "./TeamMember/teamMemberRoutes";
import teamMemberTypeRoutes from "./TeamMemberType/teamMemberTypeRoutes";
import customerAllocationRoutes from "./CustomerAllocation/customerAllocationRoutes";
import shariacontractRoutes from "./shariacontract/shariacontractRoutes";
import depositcontractRoutes from "./depositcontract/depositcontractRoutes";
import loanStatusRoutes from "./loanstatus/loanstatusRoutes";
import depositStatusRoutes from "./depositstatus/depositstatusRoutes";
import taxRoutes from "./tax/taxRoutes";
import depositChargesRoutes from "./depositcharges/depositChargesRoutes";
import pendingRegistrationRoutes from "./PendingRegistration/pendingRegistrationRoutes";

const registrationModuleRoutes = [
  {
    path: "",
    name: "registrationHome",
    component: () => import("./HomeRegistration.vue"),
    meta: {
      auth: true,
      title: "Registration",
      breadcrumb: "Registration",
      permission: [],
    },
  },

  {
    path: "users",
    name: "userHomes",
    component: () => import("./User/ListUsers.vue"),
    // component: Base,
    meta: {
      auth: true,
      title: "Users",
      breadcrumb: "Users",
      permission: ["view user", "create user", "update user", "delete user"],
    },

    children: userRegistrationRoutes,
  },

  {
    path: "groups",
    name: "groupHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Groups",
      breadcrumb: "Groups",
      permission: [
        "view group",
        "create group",
        "update group",
        "delete group",
        "approve group",
      ],
    },

    children: groupRegistrationRoutes,
  },

  {
    path: "group-categories",
    name: "groupCategoryHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Group Categories",
      breadcrumb: "Group Categories",
      permission: [
        "view groupcategory",
        "create groupcategory",
        "update groupcategory",
        "delete groupcategory",
        "approve groupcategory",
      ],
    },

    children: groupCategoryRegistrationRoutes,
  },

  {
    path: "group-sub-categories",
    name: "groupSubCategoryHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Group Sub Categories",
      breadcrumb: "Group Sub Categories",
      permission: [
        "view groupcategory",
        "create groupcategory",
        "update groupcategory",
        "delete groupcategory",
        "approve groupcategory",
      ],
    },

    children: groupSubCategoryRegistrationRoutes,
  },

  {
    path: "group-requests",
    name: "groupRequestHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Group Requests",
      breadcrumb: "Group Requests",
      permission: [
        "view grouprequest",
        "create grouprequest",
        "update grouprequest",
        "delete grouprequest",
        "approve grouprequest",
      ],
    },

    children: groupRequestRegistrationRoutes,
  },

  {
    path: "group-admins",
    name: "groupAdminHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Group Admins",
      breadcrumb: "Group Admins",
      permission: [
        "view groupadmin",
        "create groupadmin",
        "update groupadmin",
        "delete groupadmin",
        "approve groupadmin",
      ],
    },

    children: groupAdminRegistrationRoutes,
  },

  {
    path: "staged-users",
    name: "userStagingHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Staged Users",
      breadcrumb: "Staged Users",
      permission: [
        "view member",
        "create member",
        "update member",
        "delete member",
      ],
    },

    children: userStagingRegistrationRoutes,
  },

  {
    path: "paybills",
    name: "paybillHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Paybills",
      breadcrumb: "Paybills",
      permission: [
        "view paybill",
        "create paybill",
        "update paybill",
        "delete paybill",
        "approve paybill",
      ],
    },

    children: paybillRegistrationRoutes,
  },

 

  {
    path: "companies",
    name: "companyHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Companies",
      breadcrumb: "Companies",
      permission: [
        "view company",
        "create company",
        "update company",
        "delete company",
        "approve company",
      ],
    },

    children: companyRegistrationRoutes,
  },

  {
    path: "branches",
    name: "branchHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Branches",
      breadcrumb: "Branches",
      permission: [
        "view branch",
        "create branch",
        "update branch",
        "delete branch",
        "approve branch",
      ],
    },

    children: branchRegistrationRoutes,
  },

  {
    path: "members",
    name: "customerHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Members",
      breadcrumb: "Members",
      permission: [
        "view member",
        "create member",
        "update member",
        "delete member",
      ],
    },

    children: customerRegistrationRoutes,
  },

  {
    path: "staff",
    name: "employeeHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Staff",
      breadcrumb: "Staff",
      permission: [
        "view employee",
        "create employee",
        "update employee",
        "delete employee",
        "approve employee",
      ],
    },

    children: employeeRegistrationRoutes,
  },

  // {
  //     path: 'membership-stagging',
  //     name: 'membershipStaggingHomes',
  //     component: Base,
  //     meta: {
  //         auth: true,
  //         title: 'Membership Stagging',
  //         breadcrumb: 'Membership Stagging',
  //         permission: ['view member', 'create member', 'update member', 'approve member']
  //     },
  //     children: membershipStaggingRoutes
  // },

  {
    path: "vendors",
    name: "vendorHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Vendors",
      breadcrumb: "Vendors",
      permission: [
        "view vendor",
        "create vendor",
        "update vendor",
        "delete vendor",
      ],
    },

    children: vendorRegistrationRoutes,
  },

  {
    path: "financingcontracts",
    name: "financingcontractHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Financing Contracts",
      breadcrumb: "Financing Contracts",
      permission: [],
    },

    children: shariacontractRoutes,
  },

  {
    path: "depositcontracts",
    name: "depositcontractHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Deposit Contracts",
      breadcrumb: "Deposit Contracts",
      permission: [
        "view depositcontracts",
        "create depositcontracts",
        "update depositcontracts",
        "delete depositcontracts",
      ],
    },
    children: depositcontractRoutes,
  },

  {
    path: "taxes",
    name: "taxHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Taxes",
      breadcrumb: "Taxes",
      permission: ["view tax", "create tax", "update tax"],
    },

    children: taxRoutes,
  },

  {
    path: "deposit_charges",
    name: "depositChargeHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Deposit Charges",
      breadcrumb: "Deposit Charges",
      permission: [
        "view depositcharges",
        "create depositcharges",
        "update depositcharges",
      ],
    },

    children: depositChargesRoutes,
  },

  {
    path: "loanstatus",
    name: "loanstatusHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Loan Status",
      breadcrumb: "Loan Status",
      permission: [],
    },

    children: loanStatusRoutes,
  },

  {
    path: "depositstatus",
    name: "depositstatusHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Deposit Status",
      breadcrumb: "Deposit Status",
      permission: [
        "view depositstatus",
        "create depositstatus",
        "update depositstatus",
        "delete depositstatus",
      ],
    },

    children: depositStatusRoutes,
  },

  {
    path: "members-calls",
    name: "customercallHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Members Calls",
      breadcrumb: "Members Calls",
      permission: [
        "view customercall",
        "create customercall",
        "update customercall",
        "delete customercall",
      ],
    },

    children: customerCallRegistrationRoutes,
  },

  {
    path: "teams",
    name: "teamHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Teams",
      breadcrumb: "Teams",
    },

    children: teamRoutes,
  },
  {
    path: "staff-allocations",
    name: "customerAllocationHomes",
    component: Base,
    meta: {
      auth: true,
      title: "CustomerAllocations",
      breadcrumb: "CustomerAllocations",
    },

    children: customerAllocationRoutes,
  },

  {
    path: "team-members",
    name: "teamMemberHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Team Members",
      breadcrumb: "Team Members",
    },
    children: teamMemberRoutes,
  },
  {
    path: "team-member-types",
    name: "teamMemberTypeHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Team Member Types",
      breadcrumb: "Team Member Types",
    },
    children: teamMemberTypeRoutes,
  },
  /* {
    path: "staff-allocations",
    name: "customerEmployeeAllocationHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Staff Allocations",
      breadcrumb: "Staff Allocations",
      permission: [
        "view employee",
        "create employee",
        "update employee",
        "delete employee",
      ],
    },

    children: customerEmployeeAllocationRegistrationRoutes,
  }, */

  {
    path: "pending-items",
    name: "pendingRegistrationHomes",
    component: Base,
    meta: {
      auth: true,
      title: "Pending Items",
      breadcrumb: "Pending Items",
      permission: ["view makerchecker", "update makerchecker"],
    },

    children: pendingRegistrationRoutes,
  },
];

export default registrationModuleRoutes;
