import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
    namespaced: true,
    state: {
        stats: {
            loan_balance: 0,
            deposit_balance: 0,
            share_balance: 0,
        },
        transactions: [],
        deposit_accounts: {},
        deposit_statements: [],
        deposit_transactions: [],
        deposit_balances: [],
        share_accounts: [],
        deposit_statement: {},
        share_balance: {},
        share_receipt: {},
        share_balances: [],
        share_account: {},
        loan_loans: [],
        loan_statement: {},
        loan_limit: {},
        loan_products: [],
        loan_balance: {},
        loan_applications: [],
        loan_application: {},

    },
    actions,
    mutations,
    getters
}