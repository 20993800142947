<template>
  <div class="container-fluid no-gutters px-0">
    <div class="col-lg-12 mt-3xx mb-2">
      <search-bar :bread-crumb-only="true"></search-bar>

      <div class="card">
        <div class="card-body">
          <form @submit.prevent="onSubmitted">

            <div class="row">

              <div class="col-lg-4">
                <div class="form-group" :class="{
                  'has-error': model.paybill_no.$invalid,
                }">
                  <label for="paybill_no">
                    Paybill No
                    <span class="text-danger"> </span>
                  </label>
                  <input class="form-control" :class="{
                    'form-control-danger': model.paybill_no.$invalid,
                  }" :disabled="isShow" type="text" v-model="model.paybill_no.$model" />
                  <error-msg :property="model.paybill_no" />
                </div>
              </div>

              <div class="col-lg-4">
                <div class="form-group" :class="{
                  'has-error': model.paybill_name.$invalid,
                }">
                  <label for="paybill_name">
                    Paybill Name
                    <span class="text-danger"> </span>
                  </label>
                  <input class="form-control" :class="{
                    'form-control-danger': model.paybill_name.$invalid,
                  }" :disabled="isShow" type="text" v-model="model.paybill_name.$model" />
                  <error-msg :property="model.paybill_name" />
                </div>
              </div>

              

              <div class="col-lg-4">
                <div class="form-group" :class="{
                  'has-error': model.bank_account_id.$invalid,
                }">
                  <label for="bank_account_id">
                    Bank
                    <span class="text-danger"> </span>
                  </label>

                  <select class="form-control" v-model="model.bank_account_id.$model" :disabled="isShow">
                    <option value="" selected v-if="!isEdit">
                      Select Bank Account
                    </option>
                    <option v-for="(item, index) in bankaccounts" :key="index" :value="item.account_id">
                      {{ item.account_code_name }}
                    </option>
                  </select>

                  <error-msg :property="model.bank_account_id" />
                </div>
              </div>

              <div class="col-lg-4">
                <div class="form-group" :class="{
                  'has-error': model.is_default.$invalid,
                }">
                  <label for="is_default">
                    Is Default?
                    <span class="text-danger"> *</span>
                  </label>

                  <div>
                    <div class="form-check-inline my-1">
                      <div class="custom-control custom-radio">
                        <input type="radio" :disabled="isShow" id="is_default_yes" name="is_default"
                          v-model="model.is_default.$model" value="1" class="custom-control-input" />
                        <label class="custom-control-label" for="is_default_yes">Yes</label>
                      </div>
                    </div>

                    <div class="form-check-inline my-1">
                      <div class="custom-control custom-radio">
                        <input type="radio" :disabled="isShow" id="is_default_no" name="is_default"
                          v-model="model.is_default.$model" value="0" class="custom-control-input" />
                        <label class="custom-control-label" for="is_default_no">No</label>
                      </div>
                    </div>
                  </div>

                  <error-msg :property="model.is_default" />
                </div>
              </div>


              <div class="col-lg-4">
                <div class="form-group" :class="{ 'has-error': model.status_id.$invalid }">
                  <label for="status_id">Status</label>
                  <div>
                    <div class="form-check-inline my-1">
                      <div class="custom-control custom-radio">
                        <input type="radio" id="status_active" name="status_id" :disabled="isShow"
                          v-model="model.status_id.$model" value="1" class="custom-control-input" />
                        <label class="custom-control-label" for="status_active">Active</label>
                      </div>
                    </div>

                    <div class="form-check-inline my-1">
                      <div class="custom-control custom-radio">
                        <input type="radio" id="status_inactive" name="status_id" :disabled="isShow"
                          v-model="model.status_id.$model" value="99" class="custom-control-input" />
                        <label class="custom-control-label" for="status_inactive">Inactive</label>
                      </div>
                    </div>
                  </div>
                  <error-msg :property="model.status_id" />
                </div>
              </div>

              <div class="col-lg-4">
                <div class="form-group" :class="{
                  'has-error': model.config_type.$invalid,
                }">
                  <label for="config_type">
                    Configuration Type
                    <span class="text-danger"> </span>
                  </label>
                 
                  <select class="form-control" v-model="model.config_type.$model" :disabled="isShow">
                    <option value="0">None</option>
                    <option value="1">Deposit</option>
                    <option value="2">Disbursement</option>
                    <option value="3">Both</option>
                  </select>

                  <error-msg :property="model.config_type" />
                </div>
              </div>


              <div class="col-lg-4" v-if="model.config_type.$model!=0">
                <div class="form-group" :class="{
                  'has-error': model.provider.$invalid,
                }">
                  <label for="provider">
                    Provider
                    <span class="text-danger"> </span>
                  </label>
                 
                  <select class="form-control" v-model="model.provider.$model" :disabled="isShow">
                    <option value="1">Daraja</option>
                    <option value="2">Pendo</option>
                  </select>

                  <error-msg :property="model.provider" />
                </div>
              </div>


              <div class="col-lg-4" v-if="model.config_type.$model!='0'">
                <div class="form-group" >
                  <label for="model.keys.consumer_key">
                    {{ model.provider.$model==1 ? 'Consumer Key' : 'Pendo Username' }}
                    <span class="text-danger"> </span>
                  </label>
                  <input class="form-control"  
                  :disabled="isShow" type="text" 
                  v-model="model.keys.consumer_key.$model" />
                </div>
              </div>

              <div class="col-lg-4" v-if="model.config_type.$model!='0'">
                <div class="form-group" >
                  <label for="model.keys.consumer_secret">
                    {{ model.provider.$model==1 ? 'Consumer Secret' : 'Pendo Password' }}
                    <span class="text-danger"> </span>
                  </label>
                  <input class="form-control"  
                  :disabled="isShow" type="password" 
                  v-model="model.keys.consumer_secret.$model" />
                </div>
              </div>


              <div class="col-lg-4" v-if="model.config_type.$model!='0'">
                <div class="form-group" >
                  <label for="mpesa_username">
                    Mpesa Username
                    <span class="text-danger"> </span>
                  </label>
                  <input class="form-control" 
                   :disabled="isShow" type="text" 
                   v-model="model.keys.mpesa_username.$model" />
                </div>
              </div>

              <div class="col-lg-4" v-if="model.config_type.$model!='0'">
                <div class="form-group" >
                  <label for="mpesa_credential">
                    Mpesa Credential
                    <span class="text-danger"> </span>
                  </label>
                  <input class="form-control" 
    
                 :disabled="isShow" type="password" 
                 v-model="model.keys.mpesa_credential.$model" />
                 
                </div>
              </div>


              <div class="col-lg-4" v-if="model.config_type.$model!='0'">
                <div class="form-group" >
                  <label for="pass_key">
                    Pass Key
                    <span class="text-danger"> </span>
                  </label>
                  <input class="form-control" 
    
                 :disabled="isShow" type="password" 
                 v-model="model.keys.pass_key.$model" />
                 
                </div>
              </div>

              <!-- show regular form buttons -->
              <div class="col-lg-12">
                <hr />
                <div class="form-group">
                  <button class="btn btn-primary mr-3" type="submit" :disabled="model.$invalid"
                    :class="{ 'btn-secondary': model.$invalid }" v-if="!isShow">
                    <slot name="submitButton"></slot>
                  </button>

                  <button class="btn btn-primary mr-3" type="button" @click="onEdit" v-if="isShow">
                    Edit
                  </button>

                  <button class="btn btn-danger" type="button" @click="onCancelled">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, toRefs, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, url } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";



const paybillForm = {
  paybill_name: "",
  paybill_no: "",
  config_type: "0",
  bank_account_id: "",
  status_id: "1",
  paybill_id: undefined,
  is_default: false,
  provider: "1",
  keys: {
    consumer_key: "",
    consumer_secret: "",
    mpesa_username: "",
    mpesa_credential: "",
    pass_key:""
  },
};



const module = "paybills";
const financeaccounts_module = "financeaccounts";

export default defineComponent({
  name: "PaybillDetail",

  components: {},

  props: {
    isEdit: {
      type: Boolean,
      required: true,
      default: false,
    },
    isShow: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMakerChecker: {
      type: Boolean,
      required: false,
      default: false,
    },
    makerChecker: {
      type: Object,
      required: false,
    },
  },

  emits: ["submitForm", "cancelForm"],

  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    // console.log("props.makerCheckerAction === ", props.makerCheckerAction);

    // avoid destructuring props directly in the root scope of setup
    const paybillProp = toRefs(props);

    let paybill = ref(paybillForm);

    // load all companies on create component
    let payload = { report: "1" };
    store.dispatch(`${financeaccounts_module}/fetchPostingAccounts`);

    // on created. Are we in edit mode?
    if (props.isEdit || props.isShow) {
      paybill = computed(() => store.getters[`${module}/paybill`]);
    }

    // if we are in maker checker, use maker checker data
    if (paybillProp.isMakerChecker.value) {
      paybill = paybillProp.makerChecker.value?.data;
    }

    // console.log("paybill", paybill.value);

    const rules = {
      paybill_name: {},
      paybill_no: { required },
      config_type: { required },
      bank_account_id: { required },
      status_id: {},
      paybill_id: {},
      is_default:{required},
      provider:{required},
      keys: {
        consumer_key: {},
        consumer_secret: {},
        mpesa_username: {},
        mpesa_credential: {},
        pass_key:{}
      },
    };

    // validate form
    const model = useVuelidate(rules, paybill);




    function onSubmitted() {
      emit("submitForm", paybill);
    }

    function onCancelled() {
      emit("cancelForm");
    }

    function onSubmitForm() {
      emit("submitForm");
    }

    function onEdit() {
      emit("editForm", branch);
    }



    return {
      submitForm: props.submitForm,
      bankaccounts: computed(
        () => store.getters[`${financeaccounts_module}/bankingAccounts`]
      ),
      model,
      paybill: computed(() => store.getters[`${module}/paybill`]),
      // paybill,
      onSubmitted,
      onCancelled,
      onSubmitForm,
      onEdit,
      // formData,
    };
  },
});
</script>
