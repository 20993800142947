import axios from "axios";

class customerServices {
    async getStats() {
        return await axios.get('/api/v1/customer/stats')
            .then(response => {
                return Promise.resolve(response.data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
    async getTransactions(payload) {
        return await axios.get('/api/v1/customer/transactions', { params: payload })
            .then(response => {
                let data = response.data?.data.map(item => {
                    item.trans_details_json = item.trans_details_json ? JSON.parse(item.trans_details_json) : [];
                    return item
                })

                return Promise.resolve({ ...response.data, data: data })
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
    async getDepositAccounts(payload) {
        return await axios.get('/api/v1/customer/deposits', { params: payload })
            .then(response => {
                return Promise.resolve(response.data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
    async getDepositStatements(payload) {
        return await axios.get('/api/v1/customer/deposits/statements', { params: payload })
            .then(response => {
                return Promise.resolve(response.data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
    async getDepositTransactions(payload) {
        return await axios.get('/api/v1/customer/deposits/transactions', { params: payload })
            .then(response => {
                return Promise.resolve(response.data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
    async getDepositBalances(payload) {
        return await axios.get('/api/v1/customer/deposits/balances', { params: payload })
            .then(response => {
                return Promise.resolve(response.data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
    // payload has ref, amount and mod
    async postDeposit(payload) {
        return await axios.post('/api/v1/customer/deposits/deposit', payload)
            .then(response => {
                return Promise.resolve(response.data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
    async postDepositTransfer(payload) {
        return await axios.post('/api/v1/customer/deposits/transfer', payload)
            .then(response => {
                return Promise.resolve(response.data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
    async getShareAccounts(payload) {
        return await axios.get('/api/v1/customer/shares', { params: payload })
            .then(response => {
                return Promise.resolve(response.data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
    async getShareStatements(payload) {
        return await axios.get('/api/v1/customer/shares/statements', { params: payload })
            .then(response => {
                return Promise.resolve(response.data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
    async getShareBalance(payload) {
        return await axios.get('/api/v1/customer/shares/balances', { params: payload })
            .then(response => {
                return Promise.resolve(response.data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
    async postSharePurchase(payload) {
        return await axios.post('/api/v1/customer/shares/purchase', payload)
            .then(response => {
                return Promise.resolve(response.data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
    async postShareTransfer(payload) {
        return await axios.post('/api/v1/customer/shares/transfer', payload)
            .then(response => {
                return Promise.resolve(response.data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
    async getLoans(payload) {
        return await axios.get('/api/v1/customer/loans', { params: payload })
            .then(response => {
                return Promise.resolve(response.data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
    async getLoanStatement(payload) {
        return await axios.get('/api/v1/customer/loans/statement', { params: payload })
            .then(response => {
                return Promise.resolve(response.data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
    async getLoanLimit(payload) {
        return await axios.get('/api/v1/customer/loans/limit', { params: payload })
            .then(response => {
                return Promise.resolve(response.data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
    async getLoanProducts(payload) {
        return await axios.get('/api/v1/customer/loans/products', { params: payload })
            .then(response => {
                return Promise.resolve(response.data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
    async postLoanRepay(payload) {
        return await axios.post('/api/v1/customer/loans/repay', payload)
            .then(response => {
                return Promise.resolve(response.data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
    async getLoanApplications(payload) {
        return await axios.get('/api/v1/customer/loans/applications', { params: payload })
            .then(response => {
                return Promise.resolve(response.data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
    async postLoanApplication(payload) {
        return await axios.post('/api/v1/customer/loans/applications', payload)
            .then(response => {
                return Promise.resolve(response.data.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
}

const services = new customerServices();

export default services;