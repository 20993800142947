// import api from './api';
import axios from "axios";

class AuthService {
  login(user) {
    return axios
      .post("/api/v1/auth/login", {
        username: user.username,
        password: user.password,
        user_type: user.user_type,
      })
      .then(
        (res) => {
          if (res.data.data.access_token) {
            sessionStorage.setItem("access_token", res.data.data.access_token);
            sessionStorage.setItem("user", JSON.stringify(res.data.data.user));
          }
          // return res.data;
          return Promise.resolve(res.data);
        },
        (error) => {
          // console.log("Error in AuthService", error);
          return Promise.reject(error);
        }
      );
  }

  async twoFactor(user) {
    //fix login/register to use same screen
    try {
      const res = await axios.post("/api/v1/auth/twofactor", {
        // return axios.post('/api/v1/auth/step-2-two-factor', {
        code: user.code,
      });
      sessionStorage.setItem("2fa", true);
      return await Promise.resolve(res.data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async passwordTwoFactor(user) {
    try {
      const res = await axios.post("/api/v1/auth/password/two-factor", {
        code: user.code,
        username: user.username,
        user_type: user.user_type,
        idno: user.idno,
      });
      sessionStorage.setItem("2fa", true);
      return await Promise.resolve(res.data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async registerTwoFactor(user) {
    try {
      const res = await axios.post("/api/v1/auth/register-twofactor", {
        code: user.code,
        username: user.phone,
        phone: user.phone,
        phone_country: user.phone_country,
      });
      sessionStorage.setItem("2fa", true);
      return await Promise.resolve(res.data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async resendTwoFactor() {
    try {
      const res = await axios.post("/api/v1/auth/resend-twofactor");
      return await Promise.resolve(res.data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async registerResendTwoFactor(data) {
    try {
      const res = await axios.post(
        "/api/v1/auth/register-resend-twofactor",
        data
      );
      return await Promise.resolve(res.data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async passwordResendTwoFactor(data) {
    try {
      const res = await axios.post(
        "/api/v1/auth/password/resend-two-factor",
        data
      );
      return await Promise.resolve(res.data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async registerOne(data) {
    try {
      const res = await axios.post("/api/v1/auth/register-one", data);
      // sessionStorage.setItem('2fa', true);
      return await Promise.resolve(res.data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async registerTwo(data) {
    try {
      const res = await axios.post("/api/v1/auth/register-two", data);
      return await Promise.resolve(res.data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async registerThree(data) {
    try {
      const res = await axios.post("/api/v1/auth/register-three", data);
      return await Promise.resolve(res.data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async registerFourEmployment(data) {
    try {
      const res = await axios.post("/api/v1/auth/register-four-employment", data);
      return await Promise.resolve(res.data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async registerFinish(data) {
    try {
      const res = await axios.post("/api/v1/auth/register-finish", data);
      return await Promise.resolve(res.data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  // send otp for registration verification step
  async sendRegisterTwoFactor(user) {
    try {
      const res = await axios.post("/api/v1/auth/send-register-twofactor", {
        email: user.email,
        phone: user.phone,
        phone_country: user.phone_country,
        company_id: user.company_id,
      });
      return await Promise.resolve(res.data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  user() {
    return JSON.parse(sessionStorage.getItem("user"));
  }

  async logout() {
    try {
      const res = await axios.post("/api/v1/auth/logout");
      return await Promise.resolve(res.data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async forgotpassword(user) {
    return await axios.post("/api/v1/auth/forgotpassword", {
      username: user.username,
    });
  }

  async forgotPassword(username, idno, user_type) {
    return await axios.post("/api/v1/auth/password/forgot", {
      username, idno, user_type
    });
  }

  async resetPassword(username, idno, user_type, code, password, password_confirmation) {
    console.log('resetPassword Service');

    return await axios.post("/api/v1/auth/password/reset", {
      username, idno, user_type, code, password, password_confirmation
    })
  }

  register(user) {
    return axios.post("/api/v1/auth/register-three", user);
  }

  sendVerificationDetails(user) {
    return axios.post("/api/v1/auth/register-one", user);
  }

  fetch_staging_user(user) {
    return axios.get(
      `/api/v1/auth/register-fetch-staging-profile?username=${user.username}&phone_country=${user.phone_country}&company_id=${user.company_id}&send_otp=1`
    );
  }

  fetchStagingUser(params) {
    return axios.get(
      `/api/v1/auth/register-fetch-staging-profile`, { params: params }
    );
  }
}

export default new AuthService();
