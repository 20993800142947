export default {
    getStats(state) {
        return state.stats
    },
    getTransactions(state) {
        return state.transactions
    },
    getDepositAccounts(state) {
        return state.deposit_accounts
    },
    getDepositStatements(state) {
        return state.deposit_statements
    },
    getDepositTransactions(state) {
        return state.deposit_transactions
    },
    getDepositBalances(state) {
        return state.deposit_balances
    },
    getShareAccounts(state) {
        return state.share_accounts
    },
    getDepositStatement(state) {
        return state.deposit_statement
    },
    getShareBalance(state) {
        return state.share_balance
    },
    getShareReceipt(state) {
        return state.share_receipt
    },
    getShareBalances(state) {
        return state.share_balances
    },
    getShareAccount(state) {
        return state.share_account
    },
    getLoanLoans(state) {
        return state.loan_loans
    },
    getLoanStatement(state) {
        return state.loan_statement
    },
    getLoanLimit(state) {
        return state.loan_limit
    },
    getLoanProducts(state) {
        return state.loan_products
    },
    getLoanBalance(state) {
        return state.loan_balance
    },
    getLoanApplications(state) {
        return state.loan_applications
    },
    getLoanApplication(state) {
        return state.loan_application
    },
}