// import axios from "axios";
import AuthService from './../../../services/AuthService';

// const user = JSON.parse(sessionStorage.getItem('user'));
const user = JSON.parse(sessionStorage.getItem('user'));

const initialState = user
    ? { status: { loggedIn: true, twofactor: false }, user }
    : { status: { loggedIn: false, twofactor: false }, user: null };

export default {

    namespaced: true,
    state: initialState,

    getters: {

        user(state) {
            return state.user;
        },
        twofactor(state) {
            return state.status.twofactor;
        },
        loggedIn(state) {
            return state.status.loggedIn;
        }

    },

    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        twoFactorSuccess(state, user) {
            state.status.twofactor = true;
            state.user = user;
        },
        twoFactorFailure(state) {
            state.status.twofactor = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.status.twofactor = false;
            state.user = null;
            localStorage.clear();
            localStorage.setItem('vuex', "null");
            sessionStorage.clear();
            window.location = '/';
            // window.location = '/auth/login';
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
            state.status.twofactor = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
            state.status.twofactor = false;
        }
    },

    actions: {

        login({ commit }, data) {
            return AuthService.login(data).then(
                res => {
                    commit('loginSuccess', res.data.user);
                    return Promise.resolve(res.data.user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        registerTwoFactor({ commit }, data) {
            return AuthService.registerTwoFactor(data).then(
                res => {
                    //this is used in login  + use 2 fa for registration as outlined in the specs
                    // commit('twoFactorSuccess', res);
                    return Promise.resolve(res);
                })
                .catch(error => {
                    // if token has expired
                    if ((error.status === '419') || (error.status === '401') || (error.status === '422')) {
                        commit('twoFactorFailure');
                    }
                    return Promise.reject(error);
                });
        },
        sendRegisterTwoFactor({ commit }, data) {
            return AuthService.sendRegisterTwoFactor(data).then(
                res => {
                    return Promise.resolve(res);
                })
                .catch(error => {
                    return Promise.reject(error);
                });
        },

        twoFactor({ commit }, data) {
            return AuthService.twoFactor(data).then(
                res => {
                    //this is used in login  + use 2 fa for registration as outlined in the specs
                    commit('twoFactorSuccess', res);
                    return Promise.resolve(res);
                })
                .catch(error => {
                    // if token has expired
                    if ((error.status === '419') || (error.status === '401')) {
                        commit('twoFactorFailure');
                    }
                    return Promise.reject(error);
                });
        },

        resendTwoFactor({ commit }) {
            return AuthService.resendTwoFactor().then(
                data => {
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        registerResendTwoFactor({ commit }, data) {
            return AuthService.registerResendTwoFactor(data).then(
                data => {
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },

        logout({ commit }) {
            return axios.post('/api/v1/auth/logout')
                .then(
                    response => {
                        commit('logout');
                    },
                    error => {
                        commit('logout');
                    }
                );
        },

        frontendlogout({ commit }) {
            commit('logout');
        },

        verify_cred({ commit }, { email, phone_country, phone, username, company_id }) {

            const user = { email, phone_country, phone, username, company_id };
            return AuthService.sendVerificationDetails(user).then(
                response => {
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );

        },

        fetchStagingUser({ commit }, data) {

            return AuthService.fetchStagingUser(data).then(
                response => {
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );

        },

        fetch_staging_user({ commit }, { username, phone_country, company_id }) {

            const user = { username, phone_country, company_id, "send_otp": 1 };
            return AuthService.fetchStagingUser(user).then(
                response => {
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );

        },
        // payload contains {username, idno, user_type}
        forgotPassword({ commit }, user) {
            return AuthService.forgotPassword(user.username, user.idno, user.user_type).then(
                response => {
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        // payload contains {code:value, username:value, idno:value, user_type:value}
        passwordTwoFactor({ commit }, payload) {
            return AuthService.passwordTwoFactor(payload).then(
                response => {
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        // resend password reset two factor
        // payload { code: value, username: value, idno: value, user_type: value }
        passwordResendTwoFactor({ commit }, payload) {
            return AuthService.passwordResendTwoFactor(payload).then(
                response => {
                    return Promise.resolve(response);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        // payload contains {username:value, idno:value, user_type:value, password:value, password_confirmation:value}
        resetPassword({ commit }, payload) {
            console.log('resetPassword action', payload);
            let { username, idno, user_type, code, password, password_confirmation } = payload
            return AuthService.resetPassword(
                username,
                idno,
                user_type,
                code,
                password,
                password_confirmation
            ).then(
                response => {
                    return Promise.resolve(response.data)
                },
                error => {
                    return Promise.reject(error.data);
                }
            )

        },

        register({ commit }, user) {
            return AuthService.register(user).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },

        registerOne({ commit }, user) {
            return AuthService.registerOne(user).then(
                response => {
                    return Promise.resolve(response);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },

        registerTwo({ commit }, user) {
            return AuthService.registerTwo(user).then(
                response => {
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },

        registerThree({ commit }, user) {
            return AuthService.registerThree(user).then(
                response => {
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },

        registerFourEmployment({ commit }, user) {
            return AuthService.registerFourEmployment(user).then(
                response => {
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },

        registerFinish({ commit }, user) {
            return AuthService.registerFinish(user).then(
                response => {
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        }
    }

}
