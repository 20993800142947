const clientCustomerSavingsDepositsDepositsRoutes = [

    {

        path: '',
        name: 'clientCustomerSavingsDepositsDepositsHome',
        component: () => import('./ListDeposits.vue'),
        meta: {
            auth: true,
            title: 'Deposit Accounts',
            breadcrumb: 'Deposit Accounts',
            permission: []
        },

    },

    {
        path: 'create',
        name: 'clientCustomerSavingsDepositsCreateDeposit',
        component: () => import('./CreateDeposit.vue'),
        meta: {
            auth: true,
            title: 'Create Deposit',
            breadcrumb: 'Create Deposit',
            permission: ['view customer']
        }
    },

    {
        path: ':id',
        name: 'clientCustomerSavingsDepositsShowDeposit',
        component: () => import('./ShowDeposit.vue'),
        meta: {
            auth: true,
            title: 'Showing Deposit',
            breadcrumb: 'Showing Deposit',
            permission: ['view customer']
        }
    }

];

export default clientCustomerSavingsDepositsDepositsRoutes;
